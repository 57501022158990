@import '../../assets/sass/variables';

.infoBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;

  h2 {
    margin-top: 0;
    font-size: 22px;
  }
}

.icon {
  height: 100px;
  margin-bottom: 30px;
}

.text {
  text-align: center;
  max-width: 504px;
  color: $grey;
}
