@import '../../assets/sass/variables';

div.spinner {
  height: 5rem;
  width: 5rem;
  margin: 0 auto;
  border-top-color: $blue;
  border-bottom-color: $blue;
  border-left-color: $blue;
  border-width: 0.5em;
}

.container {
  display: flex;
  height: 25em;
  justify-content: center;
  align-items: center;
}
