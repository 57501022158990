@import '../../assets/sass/variables';

.tabList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.tab {
  display: inline-block;
  margin-right: 36px;
  padding: 0 0 8px 0;
  cursor: pointer;
  color: $grey;
}

.tabSelected {
  font-size: 16px;
  line-height: 1.63;
  color: $darkBlue;
  border-bottom: 1px solid $darkBlue;
}

.tabPanel {
  margin-top: 22.5px;
}

.horizontalLineBelowTabList {
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  margin-top: -1px;
}

// .listItemLink {
//   text-decoration: none;
// }

.container {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.card {
  margin: 0 1em 1em 0;
  // border: solid 1px $greyBorder;
  padding: 1em;
  // border-radius: 5px;

  &:hover {
    background-color: $greyHover;
  }
}

.surveyDeadline {
  color: $grey;
}

div.cardFooter {
  background-color: inherit;
  border-top: none;
}
