@import './_variables';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  font-family: $bodyFontFamily;
  font-size: 100%;
  line-height: 180%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main {
  padding-top: 2em;

  @media (max-width: $breakpointMobile) {
    padding-top: 0.5em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.finbb-container {
  //min-width: 768px;
  max-width: $maxWidth;
  width: 90%;
  padding: 0 1em;
  margin: 0 auto;
}

.narrow-container {
  //min-width: 320px;
  max-width: 530px;
}

.container {
  max-width: $maxWidth;
}

.custom-loader {
  width: 100%;
  height: auto;
}

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

input,
button,
select,
optgroup,
textarea {
  font-size: inherit;
  line-height: inherit;
}

/* hack in order to make the footer stick to the bottom */
body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App {
  padding-bottom: 4em;
}

.left-margin {
  margin-left: 20px;
}

.left-padding {
  padding-left: 2em;
}

.section-description {
  color: $grey;
}

.top-margin {
  margin-top: 20px;
}

.reset-top-margin {
  margin-top: 0;
}

.double-vertical-margin {
  margin: 40px 0;
}

.text-center {
  text-align: center;
}

.double-vertical-margin-bottom {
  margin: 0 0 40px 0;
}

.mta-footer {
  margin: 40px 0;

  div {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
  }
}

.mta-logo {
  max-width: 350px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inline-block {
  display: inline-block;
}

.link-grey {
  color: $darkGrey;
}

.loading-indicator {
  max-width: 100%;
}

.wrap-words {
  word-wrap: break-word;
}

.bold {
  font-weight: 600;
}
