@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
// @import "~bootstrap/scss/reboot";
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/spinners';

// redux toastr
@import '~react-redux-toastr/src/styles/index';

@import url('https://fonts.googleapis.com/css?family=Mukta&display=swap');

// config
@import './assets/sass/_variables';

// import dom elements
@import './assets/sass/components/header';
@import './assets/sass/components/footer';
@import './assets/sass/components/btn';

// page styles
@import './assets/sass/pages/userpage';
@import './assets/sass/pages/staticpages';

// general styles
@import './assets/sass/forms';

// print stylesheet
@import './assets/sass/print';

// common styles
@import './assets/sass/common';
