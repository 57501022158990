@import '../../assets/sass/variables';

.container {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.card {
  margin: 0 1em 1em 0;
  padding: 1em;

  &:hover {
    background-color: $greyHover;
  }
}

div.cardFooter {
  background-color: inherit;
  border-top: none;
}
