.suomifi {
  text-decoration: none;
  text-align: center;
  margin: 2em 0;

  &:hover {
    text-decoration: none;
  }
}

.container {
  width: 20em;
  margin: 0 auto;
}
