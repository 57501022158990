.userHeader {
  h1 {
    margin-top: 2em;
    font-size: 26px;
    margin-bottom: 2em;
    text-align: center;
  }
}

.logo {
  margin: 100px 0 90px 0;
  text-align: center;
}
