@media print {
  header.header,
  footer {
    display: none;
  }

  /* file uploader sections on forms */
  [class^='file-uploader_dropZone'],
  [class^='file-uploader_dropZone'] button {
    display: none;
  }

  /* form submit buttons */
  .form-submit-row {
    display: none;
  }
}
