@import '../_variables';

ul.static-list {
  list-style-type: none;
}

.static-service-detail {
  padding-right: 10px;
}
.static-container {
  display: flex;
  max-width: 500px;
  justify-content: flex-start;

  > * {
    flex-basis: 50%;
  }

  @media (max-width: $breakpointMobile) {
    flex-direction: column;
  }
}
.static-block {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1.static-h1 {
  @media (max-width: $breakpointMobile) {
    font-size: 2rem;
  }
}
