.title {
  font-size: 26px;
  margin: 0;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 0;
}

.rightChildren > * {
  margin-left: 1em;
}
