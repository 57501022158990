header.header {
  background: $blue;
  color: #fff;

  .finbb-container {
    position: relative;
  }

  .logo {
    display: block;
    width: 200px;
    height: 32px;
    background: url(../../img/omabiopankki_logo_white.svg) no-repeat 0 0;
    background-size: cover;
    margin-right: 3em;

    @media (max-width: $breakpointMobile) {
      width: 142px;
      height: 22.4px;
      margin-right: 1em;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 0;

    @media (max-width: $breakpointMobile) {
      padding: 15px 0;
    }
  }

  nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;

    .nav-link.active,
    .nav-link:hover {
      background: $darkerBlue;
      text-decoration: none;
      border-radius: 2px;
    }

    .nav-link {
      margin-right: 20px;
      padding: 1px 10px;

      @media (max-width: $breakpointMobile) {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    button {
      background: transparent;
      color: #fff;
      border-radius: 0;
      padding: 8px 10px;
      margin-left: 1em;
      border: 0;
      outline: none;
      font-size: 16px;
      cursor: pointer;
      font-size: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    &.secondary {
      justify-content: flex-end;
    }
  }

  h2 {
    margin-top: 0;
  }

  .search {
    padding-top: 3em;
    padding-bottom: 5.5em;
    display: flex;
    justify-content: center;
    transition: padding 0.25s ease-in-out;

    input {
      width: 100%;
    }
  }

  &.collapsed .search {
    padding-top: 0.5em;
    padding-bottom: 3em;
  }

  .search-container {
    width: 70%;
    position: relative;

    .fake-btn {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      background: $blue;
      color: #fff;
      top: 11px;
      bottom: 11px;
      right: 11px;
      border-radius: 5px;
      padding: 15px 30px;
    }
  }

  .react-autosuggest__input {
    margin-bottom: 0;
    padding: 25px 140px 25px 60px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    background-image: url(../../img/search.svg);
    background-repeat: no-repeat;
    background-position: 20px center;
  }

  .bbp {
    font-size: 20px;
  }
}
