$footerBreakpoint: 500px;

.footer-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

footer {
  padding: 4em 0 5em 0;
  margin-top: 2em;
  color: $grey;
  background: $greyHover;
  border-top: 1px solid $lightGrey;

  a {
    color: $grey;
    text-decoration: none;

    &:hover {
      color: darken($grey, 20%);
    }
  }

  p {
    margin: 0;
  }

  .footer-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0 2em 0;

    &:last-child {
      padding-bottom: 0;
    }

    &.no-flex {
      display: block;
    }

    @media (max-width: $footerBreakpoint) {
      display: block;
    }
  }

  .footer-title {
    color: #000;
    font-size: 26px;
    margin-bottom: 0.8em;
  }

  .footer-copy {
    padding-right: 2em;
  }

  .footer-privacy {
    text-decoration: underline;
  }

  .primary {
    max-width: 400px;
  }

  .secondary {
    //max-width: 400px;
    display: flex;

    @media (max-width: $footerBreakpoint) {
      display: block;
    }

    a {
      margin-right: 1em;
    }
  }

  .avainlippu {
    width: 30px;

    @media (max-width: $footerBreakpoint) {
      display: none;
    }
  }
}